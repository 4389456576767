export const saveLanguage = lng => {
  localStorage.setItem('lng', lng);
};
export const deleteLanguage = () => {
  localStorage.setItem('lng', null);
};

export const getLanguage = () => {
  return localStorage.getItem('lng');
};
