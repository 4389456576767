import {ReactSVG} from 'react-svg';
import {FormattedMessage} from 'react-intl';
import globe from '../assets/images/icon/globe.svg';
import github from '../assets/svg/github.svg';
import appstore from '../assets/images/icon/appstore.jpg';
import playstore from '../assets/images/icon/playstore.png';

const Card = ({title, url, icons, description, mobile, frontendUrl, backendUrl, viewUrl, appstoreUrl, playstoreUrl, mobileUrl, twitterLink}) => {
  return (
    <div className="flex flex-col justify-between w-full lg:w-96 rounded bg-gray-50 p-4 lg:p-6 dark:bg-gray-700">
      <div className="flex flex-row justify-start items-center mb-4">
        <h3 className=" text-gray-700 font-bold text-2xl dark:text-gray-200">{title}</h3>
      </div>
      <div className={`flex flex-col items-center justify-start `}>
        <img src={url} className={`object-cover object-top ${mobile ? 'w-44' : 'w-11/12 rounded'} `} alt={title} />
      </div>
      <div className="flex flex-col items-center justify-start">
        <p className={`text-gray-700 text-md leading-loose p-4 lg:p-6 dark:text-gray-200`}>
          {description
            ? description
            : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud'}
          {twitterLink && (
            <a className="text-blue-500" href={twitterLink}>
              <FormattedMessage id="here" />
            </a>
          )}
        </p>
      </div>
      <div className="flex  flex-row items-center justify-between mb-6">
        {icons.map(i => (
          <div key={i.name} className={`flex flex-col justify-center items-center`}>
            <img src={i.url} className={`${i.w} mb-2 ${i.className}`} alt={i.name} />
            <span className="text-gray-700 font-bold text-xs break-all dark:text-gray-200">{i.name}</span>
          </div>
        ))}
      </div>
      <div className={`flex flex-row items-center justify-evenly `}>
        {backendUrl && (
          <a
            rel="noreferrer"
            href={backendUrl}
            target="_blank"
            className="flex flex-row justify-start items-center bg-white rounded px-4 py-2 cursor-pointer mr-3 dark:bg-gray-600 dark:text-gray-200"
          >
            <ReactSVG src={github} className="w-4 mr-1 fill-current dark:text-gray-200" alt="github" />
            <span className="text-sm">Backend</span>
          </a>
        )}
        {frontendUrl && (
          <a
            rel="noreferrer"
            href={frontendUrl}
            target="_blank"
            className="flex flex-row justify-start items-center bg-white rounded px-4 py-2 cursor-pointer mr-3 dark:bg-gray-600 dark:text-gray-200"
          >
            <ReactSVG src={github} className="w-4 mr-1 fill-current dark:text-gray-200" alt="github" />
            <span className="text-sm">Frontend</span>
          </a>
        )}

        {viewUrl && (
          <a rel="noreferrer" href={viewUrl} target="_blank">
            <button
              type="button"
              className="flex flex-row justify-center items-center px-4 py-2 bg-gradient-to-r from-indigo-900 to-purple-800 hover:from-purple-800 hover:to-purple-700  text-white dark:text-gray-200 font-bold rounded"
            >
              <img src={globe} className="w-4 mr-2" alt="view online" />
              <span className="text-sm">Try it!</span>
            </button>
          </a>
        )}
        {appstoreUrl && (
          <a rel="noreferrer" href={appstoreUrl} target="_blank">
            <img src={appstore} style={{width: 110, height: 34}} className="rounded" alt="appstore" />
          </a>
        )}
        {playstoreUrl && (
          <a rel="noreferrer" href={playstoreUrl} target="_blank">
            <img src={playstore} style={{width: 110, height: 34}} alt="playstore" />
          </a>
        )}
        {mobileUrl && (
          <a
            rel="noreferrer"
            href={mobileUrl}
            target="_blank"
            className="flex flex-row justify-center items-center bg-white rounded px-4 py-2 cursor-pointer mr-3 dark:bg-gray-600 dark:text-gray-200 w-14"
          >
            <ReactSVG src={github} className="w-5 mr-1 fill-current dark:text-gray-200" alt="github" />
          </a>
        )}
      </div>

      {/* {mobileUrl && (
        <div className="flex flex-row items-center justify-center mt-4">
          <a
            rel="noreferrer"
            href={mobileUrl}
            target="_blank"
            className="flex flex-row justify-start items-center bg-white rounded px-4 py-2 cursor-pointer mr-3 dark:bg-gray-600 dark:text-gray-200 w-24"
          >
            <ReactSVG src={github} className="w-4 mr-1 fill-current dark:text-gray-200" alt="github" /> <span className="text-sm">GitHub</span>
          </a>
        </div>
      )} */}
    </div>
  );
};

export default Card;
