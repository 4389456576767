import React from 'react';
import {FormattedMessage} from 'react-intl';
import {saveTheme, deleteTheme} from '../util/theme';
import {saveLanguage} from '../util/lng';
import character from '../assets/svg/character 16.svg';

const GreetingContainer = ({locale, setLocale, theme, setTheme}) => {
  const changeTheme = e => {
    const t = e.target.checked ? 'dark' : 'light';
    if (t === 'light') {
      deleteTheme('dark');
    }
    setTheme(t);
    saveTheme(t);
  };

  const changeLanguage = e => {
    setLocale(e.target.value);
    saveLanguage(e.target.value);
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row justify-start items-center h-full p-4">
          <h4 className="font-sans text-xl font-bold text-gray-800 dark:text-gray-200 md:text-2xl whitespace-nowrap">Bryan Mtz</h4>
        </div>
        <div className="flex flex-row justify-start items-center h-full">
          <div className="mr-2">
            <div className="relative inline-block w-6 lg:w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
                type="checkbox"
                name="toggle"
                id="toggle"
                className="toggle-checkbox absolute block w-4 lg:w-6 h-4 lg:h-6 rounded-full bg-white border-2 lg:border-4 appearance-none cursor-pointer"
                checked={theme === 'dark'}
                onChange={changeTheme}
              />
              <label htmlFor="toggle" className="toggle-label block overflow-hidden h-4 lg:h-6 rounded-full bg-gray-300 cursor-pointer"></label>
            </div>
            <label htmlFor="toggle" className="text-xs text-gray-700 ">
              🌙
            </label>
          </div>
          <select value={locale} className="form-select block bg-gray-50 dark:bg-gray-900 dark:text-gray-200 pr-7 pl-2 text-sm" onChange={changeLanguage}>
            <option value="en" className="text-xs">
              EN 🇺🇸
            </option>
            <option value="es" className="text-xs">
              ES 🇲🇽
            </option>
          </select>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-evenly items-center p-4 md:p-20 lg:p-10 space-y-10 background-svg dark:bg-gray-900">
        <div className="flex flex-col w-full lg:w-1/2 items-center ">
          <h1 className="font-sans text-left text-5xl md:text-8xl font-semibold mb-6 w-full text-gray-800 dark:text-gray-200">
            <FormattedMessage id="title" />
            <span className="text-purple-900 dark:text-purple-400 font-bold text-5xl md:text-8xl ">
              <FormattedMessage id="developer" />
            </span>
          </h1>
          <p className="text-gray-700 text-xl leading-relaxed w-full dark:text-gray-200">
            <FormattedMessage id="subtitle" />
          </p>
        </div>
        <div className="flex flex-row justify-center items-center w-full lg:w-1/2">
          <img src={character} className="w-2/3" alt="developer" />
        </div>
      </div>
    </>
  );
};

export default GreetingContainer;
