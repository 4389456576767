import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import {IntlProvider} from 'react-intl';
import {ToastContainer} from 'react-toastify';
import {useState, useEffect} from 'react';
import AppLocale from './lngProvider';
import {saveTheme, getTheme} from './util/theme';
import {saveLanguage, getLanguage} from './util/lng';

import GreetingContainer from './containers/greetingContainer';
import ProjectContainer from './containers/projectContainer';
import ContactContainer from './containers/contactContainer';

function App() {
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState('en');
  const [theme, setTheme] = useState(null);
  const currentAppLocale = AppLocale[locale];

  useEffect(() => {
    const savedTheme = getTheme();
    const savedLng = getLanguage();
    if (savedTheme) {
      setTheme(savedTheme);
      saveTheme(savedTheme);
    }
    if (savedLng) {
      setLocale(savedLng);
      saveLanguage(savedLng);
    }
  }, []);

  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <GreetingContainer locale={locale} setLocale={setLocale} theme={theme} setTheme={setTheme} />
        <ProjectContainer />
        <ContactContainer loading={loading} setLoading={setLoading} />
        <ToastContainer />
      </IntlProvider>
    </div>
  );
}

export default App;
