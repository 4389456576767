import React from 'react';
import {ReactSVG} from 'react-svg';
import {FormattedMessage, useIntl} from 'react-intl';
import emailjs from 'emailjs-com';
import {toast} from 'react-toastify';
import email from '../assets/svg/email.svg';
import github from '../assets/svg/github.svg';
import twitter from '../assets/svg/twitter.svg';
import loadingImage from '../assets/images/loading.gif';

const ContactContainer = ({loading, setLoading}) => {
  const intl = useIntl();

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    emailjs.sendForm(process.env.REACT_APP_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_KEY, e.target, process.env.REACT_APP_USER_KEY).then(
      result => {
        console.log(result.text);
        toast.success(intl.formatMessage({id: 'message.sucess'}), {position: 'bottom-center', autoClose: 2500});
        const form = document.getElementsByName('contact-form')[0];
        form.reset();
        setLoading(false);
      },
      error => {
        console.log(error.text);
        toast.error(intl.formatMessage({id: 'message.error'}), {position: 'bottom-center', autoClose: 2500});
        setLoading(false);
      },
    );
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col justify-center items-center p-4 md:p-10 lg:p-10 background-svg dark:bg-gray-900">
        <h4 className="font-sans text-4xl lg:text-6xl font-bold text-purple-900 dark:text-purple-400 mb-4 md:mb-10 lg:mb-10">
          <FormattedMessage id="contact" />
        </h4>
        <form name="contact-form" className="flex flex-col w-full md:w-2/3 xl:w-1/3 rounded bg-white shadow-lg p-6 dark:bg-gray-800 mb-8 md:mb-10 lg:mb-10" onSubmit={onSubmit}>
          <label className="block mb-4">
            <span className="text-gray-700 dark:text-gray-200">
              <FormattedMessage id="name" />
            </span>
            <input
              required
              name="from_name"
              type="text"
              className="form-input mt-1 block w-full border rounded dark:bg-gray-700 dark:border-gray-800 dark:text-gray-200 dark:placeholder-gray-400"
              placeholder={intl.formatMessage({id: 'name.placeholder'})}
            />
          </label>
          <label className="block mb-4">
            <span className="text-gray-700 dark:text-gray-200">
              <FormattedMessage id="subject" />
            </span>
            <input
              required
              name="subject"
              type="text"
              className="form-input mt-1 block w-full border rounded dark:bg-gray-700 dark:border-gray-800 dark:text-gray-200 dark:placeholder-gray-400"
              placeholder={intl.formatMessage({id: 'subject.placeholder'})}
            />
          </label>
          <label className="block mb-4">
            <span className="text-gray-700 dark:text-gray-200">
              <FormattedMessage id="message" />
            </span>
            <textarea
              required
              name="message"
              className="form-textarea mt-1 block w-full border rounded dark:bg-gray-700 dark:border-gray-800 dark:text-gray-200 dark:placeholder-gray-400"
              rows="3"
              placeholder={intl.formatMessage({id: 'message.placeholder'})}
            />
          </label>
          <div class="g-recaptcha" data-sitekey="6Leb5v4bAAAAANK539wkeBLXB1ij6DDWM3xWUywT"></div>
          <br />
          <button
            type="submit"
            className={`flex flex-row justify-center items-center bg-gradient-to-r from-indigo-900 to-purple-800 hover:from-purple-800 hover:to-purple-700  text-white dark:text-gray-200 p-2 font-bold rounded ${
              loading ? 'cursor-not-allowed' : ''
            }`}
            disabled={loading}
          >
            {loading ? <img src={loadingImage} className="w-6 mr-2" alt="loading" /> : <FormattedMessage id="send" />}
          </button>
        </form>
        <div className="flex flex-row justify-evenly items-center h-full w-3/4 lg:w-1/3">
          <a rel="noreferrer" href="https://twitter.com/bryanmtzw" target="_blank">
            <ReactSVG src={twitter} className="w-8 h-8 fill-current  dark:text-gray-200" alt="twitter" />
          </a>
          <a rel="noreferrer" href="https://github.com/Bryancm" target="_blank">
            <ReactSVG src={github} className="w-8 h-8 fill-current dark:text-gray-200" alt="github" />
          </a>
          <a href="mailto:bryan.mtzs@gmail.com">
            <ReactSVG src={email} className="w-8 h-8 fill-current dark:text-gray-200" alt="linkedin" />
          </a>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center w-full">
        <div className="flex flex-row justify-end items-center h-full p-4">
          <h4 className="font-sans text-xl font-bold text-gray-800 dark:text-gray-200 md:text-2xl">Bryan Mtz</h4>
        </div>
      </div>
    </div>
  );
};

export default ContactContainer;
