import reactIcon from '../images/icon/react-icon.png';
import jsIcon from '../images/icon/js-icon.png';
import sqlLiteIcon from '../images/icon/sqlLite-icon.png';
import apiIcon from '../images/icon/apiIcon.svg';
import ffmpegIcon from '../images/icon/ffmpeg.png';
import nodeIcon from '../images/icon/nodeIcon.png';
// import reduxIcon from '../images/icon/reduxIcon.png';
import jwtIcon from '../images/icon/jwtIcon.svg';
import sqlIcon from '../images/icon/sqlIcon.png';
import awsIcon from '../images/icon/aws.png';
// import socketIcon from '../images/icon/socketIcon.svg';
// import mongoIcon from '../images/icon/mongodbIcon.png';
import admobIcon from '../images/icon/admobIcon.svg';
import tailwindIcon from '../images/icon/tailwindIcon.png';
import lottieIcon from '../images/icon/lottie.jpg';

export const BasicAdminIcons = [
  {url: reactIcon, name: 'React', className: '', w: 'w-12'},
  {url: nodeIcon, name: 'Node js', className: 'rounded ', w: 'w-10'},
  {url: jwtIcon, name: 'JWT', className: '', w: 'w-9'},
  {url: sqlIcon, name: 'SQL DB', className: 'rounded bg-white', w: 'w-9'},
  {url: awsIcon, name: 'AWS', className: '', w: 'w-9'},
];

export const AnidoroIcons = [{url: reactIcon, name: 'React', className: '', w: 'w-12'}];

export const WebIcons = [
  {url: reactIcon, name: 'React', className: '', w: 'w-12'},
  {url: jsIcon, name: 'JavaScript', className: 'rounded', w: 'w-9'},
  {url: tailwindIcon, name: 'Tailwindcss', className: '', w: 'w-9'},
];

export const AnimeRandomIcons = [
  {url: reactIcon, name: 'React Native', className: '', w: 'w-12'},
  {url: jsIcon, name: 'JavaScript', className: 'rounded', w: 'w-9'},
  {url: sqlLiteIcon, name: 'SQLlite', className: '', w: 'w-12'},
  {url: admobIcon, name: 'Admob', className: '', w: 'w-9'},
];

export const SakugaBIcons = [
  {url: reactIcon, name: 'React Native', className: '', w: 'w-12'},
  {url: jsIcon, name: 'JavaScript', className: 'rounded', w: 'w-9'},
  {url: apiIcon, name: 'API', className: 'dark:bg-gray-200 rounded p-0.5', w: 'w-9'},
  {url: ffmpegIcon, name: 'Ffmpeg', className: '', w: 'w-9'},
];

export const SakutrainIcons = [
  {url: reactIcon, name: 'React Native', className: '', w: 'w-12'},
  {url: jsIcon, name: 'JavaScript', className: 'rounded', w: 'w-9'},
  {url: apiIcon, name: 'API', className: 'dark:bg-gray-200 rounded p-0.5', w: 'w-9'},
  {url: lottieIcon, name: 'Lottie Files', className: 'dark:bg-gray-200 rounded p-0.5', w: 'w-9'},
];
