export const saveTheme = theme => {
  localStorage.setItem('theme', theme);
  document.documentElement.classList.add(theme);
};
export const deleteTheme = theme => {
  localStorage.setItem('theme', null);
  document.documentElement.classList.remove(theme);
};

export const getTheme = () => {
  return localStorage.getItem('theme');
};
