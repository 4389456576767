import React from 'react';
import {FormattedMessage} from 'react-intl';
import Card from '../components/Card';
import app1 from '../assets/images/screenshots/sakugaMobile.png';
import app2 from '../assets/images/screenshots/animeRandom.png';
import app3 from '../assets/images/screenshots/sakutrain1.png';
import web1 from '../assets/images/screenshots/invoiceAdmin.png';
import web2 from '../assets/images/screenshots/anidoro.png';
import web3 from '../assets/images/screenshots/web2.png';
import {BasicAdminIcons, AnidoroIcons, WebIcons, AnimeRandomIcons, SakugaBIcons, SakutrainIcons} from '../assets/icons';

const ProjectContainer = () => {
  return (
    <div className="flex flex-col justify-center items-center px-4 lg:px-8 pt-8 pb-10 bg-gray-100 dark:bg-gray-800">
      <h4 className="font-sans text-4xl md:text-6xl font-bold text-purple-900 mb-8 dark:text-purple-400">
        <FormattedMessage id="mobile.projects" />
      </h4>
      <div className="grid grid-col-3 grid-rows-none md:grid-rows-2 xl:grid-rows-1 grid-flow-row md:grid-flow-col gap-8 lg:gap-8 bg-gray-100 dark:bg-gray-800 ">
        <Card
          mobile={true}
          url={app1}
          title={<FormattedMessage id="sakugab" />}
          description={<FormattedMessage id="sakugab.description" />}
          icons={SakugaBIcons}
          appstoreUrl="https://apps.apple.com/us/app/sakuga-mobile/id1530701238"
          playstoreUrl="https://play.google.com/store/apps/details?id=com.sakugabo"
          mobileUrl="https://github.com/Bryancm/sakuga_mobile"
        />
        <Card
          mobile={true}
          url={app2}
          title={<FormattedMessage id="anime.random" />}
          description={<FormattedMessage id="anime.random.description" />}
          icons={AnimeRandomIcons}
          appstoreUrl="https://apps.apple.com/us/app/anime-random/id1522807424"
          playstoreUrl="https://play.google.com/store/apps/details?id=com.bryanmtz.randomanime"
          mobileUrl="https://github.com/Bryancm/anime_random"
        />
        <Card
          mobile={true}
          url={app3}
          title={<FormattedMessage id="sakutrain" />}
          description={<FormattedMessage id="sakutrain.description" />}
          icons={SakutrainIcons}
          appstoreUrl="https://apps.apple.com/us/app/sakutrain/id1576075738"
          playstoreUrl="https://play.google.com/store/apps/details?id=com.sakutrain"
          mobileUrl="https://github.com/Bryancm/sakutrain-app"
        />
      </div>
      <h4 className="font-sans text-4xl md:text-6xl mt-8 mb-8 font-bold text-purple-900 dark:text-purple-400">
        <FormattedMessage id="web.projects" />
      </h4>
      <div className="grid grid-col-3 grid-rows-none md:grid-rows-2 xl:grid-rows-1 grid-flow-row md:grid-flow-col gap-8 lg:gap-8 bg-gray-100 dark:bg-gray-800 ">
        <Card
          url={web1}
          title={<FormattedMessage id="invoice.admin" />}
          description={<FormattedMessage id="invoice.admin.description" />}
          icons={BasicAdminIcons}
          viewUrl="http://ec2-3-143-208-141.us-east-2.compute.amazonaws.com"
          // frontendUrl="https://github.com/Bryancm/invoice-admin-frontend"
          // backendUrl="https://github.com/Bryancm/invoice-admin-api"
        />
        <Card
          url={web3}
          title={<FormattedMessage id="this.web.page" />}
          description={<FormattedMessage id="this.web.page.description" />}
          icons={WebIcons}
          // frontendUrl="https://github.com/Bryancm/web"
        />
        <Card
          url={web2}
          title={<FormattedMessage id="anidoro" />}
          description={<FormattedMessage id="anidoro.description" />}
          icons={AnidoroIcons}
          // frontendUrl="https://github.com/Bryancm/anidoro"
          // backendUrl="https://github.com/Bryancm/anidoro"
          twitterLink="https://twitter.com/bryanmtzw"
        />
      </div>
    </div>
  );
};

export default ProjectContainer;
